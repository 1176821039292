<template>
  <div>
    <v-form ref="form" v-model="valid">
      <template v-if="!advisorId || !origin">
        <v-card-subtitle class="mb-2 pl-0">Configuration</v-card-subtitle>
        <v-row>
          <v-col class="pb-0">
            <SearchSelectAdvisor
              v-if="!advisorId"
              v-model="selectedAdvisor"
              :required="true"
              :multiple="false"
              label="Search for Advisor (required)"
            /> </v-col
        ></v-row>
        <v-row>
          <v-col class="pb-0">
            <v-select
              v-if="!origin"
              v-model="prospect.origin"
              :items="origins"
              :menu-props="{ maxHeight: '400' }"
              hint="Origin flag that determines where the emails originate from for HubSpot automation."
              label="Origin"
              clearable
              outlined
              persistent-hint
              prepend-icon="mdi-sprout"
              class="mb-4"
            ></v-select> </v-col
        ></v-row>

        <v-divider class="my-3"></v-divider>
      </template>

      <v-card-subtitle class="pl-0">Prospect Details</v-card-subtitle>
      <v-row>
        <v-col class="col-12 col-md-6 pb-0">
          <v-text-field
            v-model="prospect.firstName"
            label="First Name"
            outlined
            :rules="rules.firstNameRules"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-6 pb-0">
          <v-text-field
            v-model="prospect.lastName"
            label="Last Name"
            outlined
            :rules="rules.lastNameRules"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-6 pb-0">
          <v-text-field
            v-model="prospect.email"
            label="Email Address (required)"
            outlined
            :rules="rules.emailRules"
            type="email"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-md-6 pb-0">
          <v-text-field
            v-model="prospect.phone"
            v-maska="'###-###-####'"
            label="Phone"
            hint="Must be a real number with format: 555-555-5555"
            persistent-hint
            outlined
            clearable
            type="tel"
          ></v-text-field>
        </v-col>

        <v-col class="col-12 col-lg-6 pb-0">
          <v-text-field
            v-model="prospect.address.streetAddress1"
            label="Street Address 1"
            outlined
            type="text"
          ></v-text-field>
        </v-col>

        <v-col class="col-12 col-lg-6 pb-0">
          <v-text-field
            v-model="prospect.address.streetAddress2"
            label="Street Address 2"
            outlined
            type="text"
          ></v-text-field>
        </v-col>

        <v-col class="col-12 col-md-6 pb-0">
          <v-select
            v-model="prospect.address.state"
            :items="states"
            item-text="name"
            item-value="abbreviation"
            :menu-props="{ maxHeight: '400' }"
            label="State"
            clearable
            outlined
          ></v-select>
        </v-col>
        <v-col class="col-12 col-md-6 pb-0">
          <v-text-field
            v-model="prospect.address.postalCode"
            label="Postal Code"
            outlined
            clearable
            :rules="rules.zipCodeRules"
            type="text"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider class="mt-6 mb-8"></v-divider>
      <div class="d-flex justify-center mb-4">
        <v-btn text color="error" @click="handleReset" class="mr-4"
          >Clear</v-btn
        >
        <v-btn
          :disabled="!valid"
          @click.stop="handleShowConfirmDialog"
          color="primary"
          >Send Email Invite</v-btn
        >
      </div>
    </v-form>

    <v-dialog v-model="showDialog" persistent max-width="500">
      <v-card>
        <v-overlay
          v-if="dialogData.type === 'warning'"
          :value="loading"
          color="#091437"
          opacity="0.85"
          absolute="absolute"
          class="text-center"
        >
          <h3 class="mb-4">Sending invite...</h3>
          <v-progress-circular
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-overlay>

        <v-card-title class="mb-4 text-h5 white--text" :class="dialogData.type">
          {{ dialogData.title }}
        </v-card-title>
        <v-card-text class="d-flex flex-column align-center justify-center">
          <v-icon
            v-if="dialogData.type !== 'warning'"
            :color="dialogData.type"
            x-large
            >{{
              dialogData.type === "success" ? "mdi-check" : "mdi-close"
            }}</v-icon
          >
          <div v-html="dialogData.body"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            text
            @click="
              {
                showDialog = false;
                loading = false;
                handleReset;
              }
            "
            class="mr-4"
          >
            {{ dialogData.type === "warning" ? "Cancel" : "Close" }}
          </v-btn>

          <v-btn
            v-if="dialogData.type === 'warning'"
            text
            color="primary"
            @click="handleConfirm"
          >
            Confirm
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { validatePhone } from "@/services/validatePhoneNumber";
import { createProspect } from "@/graphql/mutations";
import SearchSelectAdvisor from "@/components/SearchSelectAdvisor";
import states from "@/assets/states.json";

const initialProspect = function () {
  return {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    address: {
      streetAddress1: null,
      streetAddress2: null,
      city: null,
      state: null,
      postalCode: null,
    },
    advisorId: null,
    origin: null,
  };
};

export default {
  props: {
    advisorId: {
      type: String,
      required: false,
    },
    origin: {
      type: String,
      required: false,
    },
    resetInviteForm: {
      type: Boolean,
      required: false,
    },
  },
  components: { SearchSelectAdvisor },
  data() {
    return {
      states: states,
      origins: ["invite", "import"],
      prospect: new initialProspect(),
      valid: false,
      loading: false,
      selectedAdvisor: null,
      showDialog: false,
      dialogData: { title: null, body: null, type: null },
      rules: {
        required: [
          (v) => {
            return !!v || "This field is required.";
          },
        ],
        emailRules: [
          (v) => !!v || "Email address is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        firstNameRules: [
          (v) =>
            !v || v.length >= 2 || "First name must be at least two letters",
        ],
        lastNameRules: [
          (v) =>
            !v || v.length >= 2 || "Last name must be at least two letters",
        ],
        zipCodeRules: [
          (v) =>
            !v ||
            /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) ||
            "Please enter a valid postal code.",
        ],
      },
    };
  },
  watch: {
    resetInviteForm(v) {
      if (v) {
        this.handleReset();
      }
    },
  },
  mounted() {
    if (this.origin) {
      this.prospect.origin = origin;
    }
    if (this.advisorId) {
      this.prospect.advisorId = this.advisorId;
    }
  },
  methods: {
    handleReset() {
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.prospect = new initialProspect();
      });
    },
    async handleValidatePhone(num) {
      try {
        // validate phone number
        const response = await validatePhone(num);
        if (response.message) {
          throw new Error(response.message);
        } else {
          return true;
        }
      } catch (errorMsg) {
        this.showDialog = true;
        this.dialogData = {
          title: "There is a problem!",
          body: `<div class="mt-2 text-center">
              <p class="error--text"><strong>${num}</strong></p>
              <p>Please enter a valid US phone number for the prospect.</p></div>`,
          type: "error",
        };

        return false;
      }
    },
    handleShowConfirmDialog() {
      this.showDialog = true;
      this.dialogData = {
        title: " Are you sure?",
        body: ` <p>
            An email will be sent to
            <strong>${this.prospect.email}</strong> inviting them to take the Risk
            Score Survey.
          </p>

          <p>Are you sure you want to send the invite?</p>`,
        type: "warning",
      };
    },
    async handleConfirm() {
      this.loading = true;

      if (this.prospect.phone) {
        const isValid = await this.handleValidatePhone(this.prospect.phone);

        if (!isValid) {
          return;
        }
      }

      const prospectData = this.prospect;

      if (!this.advisorId && this.selectedAdvisor) {
        prospectData["advisorId"] = this.selectedAdvisor.id;
      }

      try {
        /// Add to DB
        const response = await API.graphql(
          graphqlOperation(createProspect, { input: prospectData })
        );

        // New prospect
        let createdProspect = response.data.createProspect;
        console.log("createdprospect", createdProspect);
        if (createProspect) {
          this.showDialog = true;
          this.dialogData = {
            title: "Invite has been sent!",
            body: "",
            type: "success",
          };
        }
      } catch (error) {
        console.log("Error creating prospect ", error);

        this.showDialog = true;
        this.dialogData = {
          title: "There is a problem!",
          body: error.message,
          type: "error",
        };
      } finally {
        this.handleReset();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
