<template>
  <div>
    <header class="mb-12">
      <h1 class="mt-5">Prospect Score Survey Invite</h1>
      <p class="red--text darken-4">PLANGAP PERSONNEL ONLY!</p>
    </header>
    <v-card outlined elevation="12" max-width="1000" class="mx-auto">
      <v-card-title>Prospect Score Survey Invite</v-card-title>

      <v-card-text
        ><p>
          Send an email inviting prospects to take the Risk Score Survey.
          Advisor and Prospect Email are required.
        </p>
        <p>
          Submitting this form will create a prospect record attached to the
          selected advisor. An email will be sent to the prospect with a link to
          take the Risk Score Survey.
        </p>
        <p>
          A notification email will then be sent to the selected advisor
          regarding the prospect invitation.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text><ProspectCreateForm /> </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProspectCreateForm from "../components/ProspectCreateForm.vue";

export default {
  components: {
    ProspectCreateForm,
  },
  data() {
    return {};
  },
};
</script>
